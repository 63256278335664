import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";
import ClientPosition from "../clientPosition";
import ClientPositionStore from "./clientPositionStore";
import React from "react";
import {ombaApiScopes} from "../../../authConfig";
import OmbaPmsClientPosition from "../../../interfaces/ombaApi/ombaPmsClientPosition";
import OmbaPmsClientPositionWithLookThrough from "../../../interfaces/ombaApi/ombaPmsClientPositionWithLookThrough";
import LusidPmsClientPositionExtended from "../../../interfaces/lusid/lusidPmsClientPositionExtended";
import ClientPositionWithLookThrough from "../clientPositionWithLookThrough";
import ClientPositionExtended from "../clientPositionExtended";

class OmbaPmsClientPositionStore implements ClientPositionStore{

    baseApiUrl: string;

    account?: AccountInfo;

    instance?: IPublicClientApplication;

    private accessToken?: string;

    private useLusid?: boolean;

    constructor(baseApiUrl: string, account?: AccountInfo, instance?: IPublicClientApplication, accessToken?: string, useLUSID?: boolean){
        this.baseApiUrl = baseApiUrl;
        this.account = account;
        this.instance = instance;
        this.accessToken = accessToken;
        if (useLUSID == undefined || useLUSID == null){
            this.useLusid = false
        } else {
            this.useLusid = useLUSID
        }
    }

    setAccessToken(accessToken: string): void{
        this.accessToken = accessToken;
    }

    getAccessToken(): Promise<string>{

        if (this.accessToken != undefined){
            return new Promise((resolve, reject) => resolve(this.accessToken!))
        };

        return this.instance!.acquireTokenSilent({
            ...ombaApiScopes,
            account: this.account
        }).then((response) => {
            return response.accessToken;
        });
    }

    getClientPositionsWithLookThrough(
        representativeDate: string,
        accountNumbers: string[],
        lookThroughDepth: string,
        aggregateMethod: string
    ): Promise<ClientPositionWithLookThrough[]>{

        console.log(`⌛ Getting client positions with lookthrough for ${representativeDate}...`)

        let searchParams = new URLSearchParams({
            QueryType: "search",
            PositionDate: representativeDate,
            LookThroughDepth: lookThroughDepth || "0",
            Method: aggregateMethod
        });

        if (this.useLusid!){
            searchParams.append('UseLUSID', this.useLusid!.toString())
        }

        accountNumbers.map(accountNumber => searchParams.append("AccountNumber", accountNumber));

        return this.getAccessToken().then((accessToken) => {
            return fetch(`${this.baseApiUrl}/pmspositions/lookthrough/?` + searchParams, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
            })
                .then(response => response.json())
                .then(res => {
                    return res as OmbaPmsClientPositionWithLookThrough[]
                })
                .then(clientPositions => {
                    console.log(`✅ Got client positions with lookthrough for ${representativeDate}`)
                    return clientPositions.map(clientPosition => {
                        return new ClientPositionWithLookThrough(
                            clientPosition.Id,
                            clientPosition.RawId,
                            clientPosition.CustodianId,
                            clientPosition.FileProcessId,
                            clientPosition.AssetClass,
                            clientPosition.AccountNumber,
                            clientPosition.PositionDate,
                            clientPosition.Currency,
                            Number(clientPosition.ExchangeRate),
                            Number(clientPosition.Quantity),
                            Number(clientPosition.AvgorUnitCost),
                            Number(clientPosition.MarketPrice),
                            Number(clientPosition.MarketValue),
                            clientPosition.SecurityName,
                            clientPosition.SecurityISIN,
                            clientPosition.SecurityRIC,
                            Number(clientPosition.MarketValueReportingCurrency),
                            Number(clientPosition.AccruedInterest),
                            clientPosition.EtfIdentifiers,
                            clientPosition.UnderlyingInstrumentIdentifiers,
                            clientPosition.AssetSubClass,
                            clientPosition.SecurityCUSIP,
                            clientPosition.SecuritySymbol,
                            clientPosition.ExchangeCode,
                            clientPosition.RepresentativeDate,
                            clientPosition.ProvidedETFId,
                            clientPosition.ETFId,
                            clientPosition.ETFIdentifierType,
                            clientPosition.ETFIdentifierValue,
                            clientPosition.ETFName,
                            clientPosition.ETFDescription,
                            clientPosition.ETFCurrency,
                            clientPosition.ETFType,
                            clientPosition.HoldingsDate,
                            clientPosition.UnderlyingInstrumentId,
                            clientPosition.UnderlyingInstrumentIdentifierType,
                            clientPosition.UnderlyingInstrumentIdentifierValue,
                            clientPosition.UnderlyingInstrumentName,
                            clientPosition.UnderlyingInstrumentDescription,
                            clientPosition.UnderlyingInstrumentCurrency,
                            clientPosition.UnderlyingInstrumentType,
                            clientPosition.UnderlyingInstrumentStatus,
                            Number(clientPosition.Weight),
                            clientPosition.Lineage
                        )
                })}).catch((err) => {
                    console.log(`❌ Failed getting client positions with lookthrough for ${representativeDate}`)
                    console.log(err)
                    throw err
                });
        })
    }


    getClientPositions(
        representativeDate: string,
        accountNumbers: string[]
    ): Promise<ClientPosition[]>{

        console.log(`⌛ Getting client positions for ${representativeDate}...`)

        let searchParams = new URLSearchParams({
            QueryType: "search",
            PositionDate: representativeDate
        });

        if (this.useLusid!){
            searchParams.append('UseLUSID', this.useLusid!.toString())
        }

        accountNumbers.map(accountNumber => searchParams.append("AccountNumber", accountNumber));

        return this.getAccessToken().then((accessToken) => {
            return fetch(`${this.baseApiUrl}/pmspositions/?` + searchParams, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
            })
                .then(response => response.json())
                .then(res => {
                    return res as OmbaPmsClientPosition[]
                })
                .then(clientPositions => {
                    console.log(`✅ Got client positions for ${representativeDate}`)
                    return clientPositions.map(clientPosition => {
                        return new ClientPosition(
                            clientPosition.Id,
                            clientPosition.RawId,
                            clientPosition.CustodianId,
                            clientPosition.FileProcessId,
                            clientPosition.AssetClass,
                            clientPosition.AccountNumber,
                            clientPosition.PositionDate,
                            clientPosition.Currency,
                            Number(clientPosition.ExchangeRate),
                            Number(clientPosition.Quantity),
                            Number(clientPosition.AvgorUnitCost),
                            Number(clientPosition.MarketPrice),
                            Number(clientPosition.MarketValue),
                            clientPosition.SecurityName,
                            clientPosition.SecurityISIN,
                            clientPosition.SecurityRIC,
                            Number(clientPosition.MarketValueReportingCurrency),
                            Number(clientPosition.AccruedInterest),
                            clientPosition.AssetSubClass,
                            clientPosition.SecurityCUSIP,
                            clientPosition.SecuritySymbol,
                            clientPosition.ExchangeCode,
                        )
                }
                )}).catch((err) => {
                    console.log(`❌ Failed getting client positions for ${representativeDate}`)
                    console.log(err)
                    throw err
                });
        })
    }

    getClientPositionsExtended(
        accountNumber: string,
        representativeDate: string
    ): Promise<ClientPositionExtended[]> {

        return this.getAccessToken().then((accessToken) => {
            return fetch(`${this.baseApiUrl}/pmspositions/extended/?AccountNumber=${accountNumber}&PositionDate=${representativeDate}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
            }
            ).then(response => {
                    if (!response.ok) {
                        throw new Error(`Error fetching holdings: ${response.statusText}`);
                    }
                    return response.json();
                })
                .then(res => {
                    return res as LusidPmsClientPositionExtended[];
                })
                .then(clientPositions => clientPositions.map(clientPosition => {
                    return new ClientPositionExtended(
                        clientPosition.Id,
                        clientPosition.Id,
                        clientPosition.Id,
                        0,
                        clientPosition.AssetClass,
                        clientPosition.AccountNumber,
                        clientPosition.PositionDate,
                        clientPosition.Currency,
                        clientPosition.ExchangeRate,
                        clientPosition.Quantity,
                        clientPosition.AvgorUnitCost,
                        clientPosition.MarketPrice,
                        clientPosition.MarketValue,
                        clientPosition.SecurityName,
                        clientPosition.SecurityISIN,
                        clientPosition.SecurityRIC,
                        clientPosition.MarketValueReportingCurrency,
                        0,
                        clientPosition.ParentAccountNumber,
                        clientPosition.HoldingMarketValue,
                        clientPosition.Cost,
                        clientPosition.UnrealisedGain,
                        clientPosition.UnrealisedGainReportingCurrency,
                        clientPosition.PriceSource,
                        clientPosition.PriceEffectiveAt,
                        clientPosition.PortfolioPercentage,
                        clientPosition.UnrealisedGainPercentage,
                        clientPosition.AssetSubClass,
                        clientPosition.SecurityCUSIP,
                        clientPosition.SecuritySymbol,
                        clientPosition.ExchangeCode,
                        clientPosition.PortfolioCode
                    );
                })
            );
        }).catch(error => {
            console.error('Client Positions Extended:', error);
            throw error;
        });
    }
}

export default OmbaPmsClientPositionStore;
